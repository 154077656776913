<template>
  <div id="zf_div_Mk7a-xT2aPVO-PD9av13KxyB-Rcrv9xMwr2og5_mKXc">
    <iframe
      ref="iframeRef"
      aria-label='Download\x20our\x20SL\x20Guide' 
      frameborder="0" 
      allow="geolocation;" 
      src='https://forms.zohopublic.eu/lorraine2/form/DownloadoureBook/formperma/Mk7a-xT2aPVO-PD9av13KxyB-Rcrv9xMwr2og5_mKXc?zf_rszfm=1'
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
const { $amplitude } = useNuxtApp();

const iframeRef = ref<HTMLIFrameElement | null>(null);
  
const trackEvent = () => {
  if (!$amplitude) return;
  
  $amplitude.track('SL Guide Zoho Form Submitted', {
    page: window.location.pathname,
  });
};

onMounted(() => {
  try {
    const iframe = iframeRef.value;

    if (!iframe) return;

    iframe.style.border = "none";
    iframe.style.transition = "all 0.5s ease";

    let scriptExecuted = false;

    const executeZohoScript = () => {
      if (!scriptExecuted && window.zfutm_zfAdvLead) {
        scriptExecuted = true;
        window.zfutm_zfAdvLead.zfautm_iframeSprt();
        window.zfutm_zfAdvLead.zfautm_DHtmlSprt();
      }
    };

    const observer = new MutationObserver(() => {
      executeZohoScript();
    });

    iframe.onload = () => {
      executeZohoScript();

      if (iframe.contentDocument) {
        observer.observe(iframe.contentDocument, {
          childList: true,
          subtree: true,
        });
      }
    };
      
    window.addEventListener(
      "message",
      (event) => {
        const evntData = event.data;
  
        iframe.onload = () => {
          trackEvent();
        };
  
        if (evntData && typeof evntData === "string") {
          const zfIframeData = evntData.split("|");
          if (zfIframeData.length === 2) {
            const zfPerma = zfIframeData[0];
            const newHeight = `${parseInt(zfIframeData[1], 10) + 15}px`;
            const iframeElement = iframe;
  
            if (
              iframeElement.src.includes("formperma") &&
              iframeElement.src.includes(zfPerma)
            ) {
              if (iframeElement.style.height !== newHeight) {
                iframeElement.style.height = newHeight;
              }
            }
          }
        }
      },
      false
    );
  } catch (e) {
    console.error("Error embedding Zoho form:", e);
  }
})
</script>
  
<style lang="sass" scoped>
#zf_div_Mk7a-xT2aPVO-PD9av13KxyB-Rcrv9xMwr2og5_mKXc
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  height: 100%
  width: 100%

iframe
  border: none
  width: 100%
  height: 100%
  transition: all 0.5s ease
</style>
  